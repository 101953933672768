import React, { Component } from 'react';

import './Article.css';

class Article extends Component {
  constructor(props) {
    super(props);

    this.image = this.image.bind(this);
    this.showmore = this.showmore.bind(this);

    this.state = {
      showall: false
    }
  }

  image(item) {
    if (item.media && item.media !== '' && this.props.layout === 'card') {
      return (
        <figure>
          <img src={item.media} alt={item.title} />
        </figure>
      );
    } else {
      return null;
    }
  }

  showmore(e) {
    e.preventDefault();
    this.setState( { showall: !this.state.showall } );
  }
  
  render() {
    let image = this.image;
    let showmore = this.showmore;
    let navigate = this.props.navigate;    
    let item = this.props.item;
    let tags = item.tags.split(',');
    let l = tags.length;
    let i = 0;
    return (
      <article className="Article">
        <a href={item.link}>
          <header>
            {image(item)}
            <h1><span>{item.title}</span></h1>
          </header>
          <p>{item.content}</p>
        </a>
        <footer>
          <ul className={ "tags " + ( this.state.showall ? 'expanded' : 'collapsed' ) }>
          {
            tags.map(
              (tag, index) => {
                if ( i < 4 ) {
                  i++;
                  return <li key={index} className="tag"><a href={"/filter/by/" + escape( tag.trim())} onClick={(e) => { navigate( e, "/filter/by/" + escape( tag.trim()) ) }}>#{tag.trim()}</a></li>;
                } else if (i === 4 && l <= 5) {
                  i++;
                  return <li key={index} className="tag"><a href={"/filter/by/" + escape( tag.trim())} onClick={(e) => { navigate( e, "/filter/by/" + escape( tag.trim()) ) }}>#{tag.trim()}</a></li>;
                } else if ( i === 4 ) {
                  i++;
                  let remaining = (l - 5);
                  remaining = ( remaining < 11 ) ? '+' + remaining : '10+';
                  return <li key={index} className="tag with-switch"><a href={"/filter/by/" + escape( tag.trim())} onClick={(e) => { navigate( e, "/filter/by/" + escape( tag.trim()) ) }}>#{tag.trim()}</a> <a href="#expand" className="show" onClick={showmore}>{remaining}</a></li>;
                } else if ( i === l - 1 ) {
                  i++;
                  return <li key={index} className="tag additional with-switch"><a href={"/filter/by/" + escape( tag.trim())} onClick={(e) => { navigate( e, "/filter/by/" + escape( tag.trim()) ) }}>#{tag.trim()}</a> <a href="#collapse" className="hide" onClick={showmore}>Collapse</a></li>;
                } else {
                  i++;
                  return <li key={index} className="tag additional"><a href={"/filter/by/" + escape( tag.trim())} onClick={(e) => { navigate( e, "/filter/by/" + escape( tag.trim()) ) }}>#{tag.trim()}</a></li>;
                }
              }
            )
          }
          </ul>
        </footer>
      </article>
    );
  }
}

export default Article;
