import React, { Component } from 'react';

import './Search.css';

class Search extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.typing = this.typing.bind(this);

    this.state = {
      quicklinks: [],
      term: ''
    };
  }

  componentDidMount() {
    this.setState( { term: this.props.term, quicklinks: JSON.parse(this.props.content.quicklinks) } );
  }

  componentDidUpdate( pProps, pState ) {
    if (this.props.content.quicklinks !== pProps.content.quicklinks) {
      this.setState( { quicklinks: JSON.parse(this.props.content.quicklinks) } );
    }
    if (this.props.term !== pProps.term) {
      this.setState( { term: this.props.term } );
    }
  }

  submit(e) {
    this.props.navigate( e, '/filter/by/' + this.state.term );
  }

  typing(e) {
    this.setState( { term: e.target.value } );
  }

  render() {
    let navigate = this.props.navigate;
    return <section className="Search">
      <form onSubmit={this.submit}>
        <input type="search" name="search" value={this.state.term} placeholder="Filter by…" onChange={this.typing} />
      </form>
      <ul className="tags">
        <li className="intro">Quick filters</li>
        {
          this.state.quicklinks.map(
            (item, index) => {
              return (
                <li key={index} className="tag">
                  <a href={"/filter/by/" + escape(item)} onClick={ e => { navigate(e, '/filter/by/' + item) } }>#{item}</a>
                </li>
              )
            }
          )
        }
      </ul>
    </section>;
  }
}

export default Search;
