import React, { Component } from 'react';

import "./List.css";

import Article from "./Article";

class List extends Component {
  constructor(props) {
    super(props);

    this.clear = this.clear.bind(this);
    this.icon = this.icon.bind(this);
    this.reset = this.reset.bind(this);
    this.title = this.title.bind(this);
    this.toggleView = this.toggleView.bind(this);

    this.state = {
      cards: ( window.localStorage.getItem('cards') === 'true' ) ? true : false
    }
  }

  clear() {
    if (this.props.term && this.props.term !== '') {
      return <button className="clear" onClick={this.reset}>Clear filters</button>;
    } else {
      return null;
    }
  }

  icon() {
    if ( this.state.cards ) {
      return (
        <svg viewBox="0 0 32 32">
            <g>
              <rect x="6" y="6" width="20" height="3"></rect>
              <rect x="6" y="15" width="20" height="3"></rect>
              <rect x="6" y="24" width="20" height="3"></rect>
            </g>
        </svg>
      );
    } else {
      return (
        <svg viewBox="0 0 32 32">
          <g>
              <rect x="6" y="6" width="9" height="9"></rect>
              <rect x="18" y="6" width="9" height="9"></rect>
              <rect x="6" y="18" width="9" height="9"></rect>
              <rect x="18" y="18" width="9" height="9"></rect>
          </g>
        </svg>
      );
    }
  }

  reset(e) {
    this.props.navigate(e, '/');
  }

  title() {
    if ( this.props.term && this.props.term !== '' ) {
      return 'Bookmarks filtered by <em>' + this.props.term + '</em>';
    } else {
      return "Bookmarks";
    }
  }

  toggleView(e) {
    e.preventDefault();
    window.localStorage.setItem('cards', !this.state.cards);
    this.setState({ cards: !this.state.cards });
  }

  render() {
    return (
      <section className="List">
        <h1><span dangerouslySetInnerHTML={{ __html: this.title() }}></span> {this.clear()} <button className="toggle" onClick={this.toggleView}>{this.icon()}</button></h1>
        




        <ul className={this.state.cards ? "cards" : "list"}>
        {
          this.props.bookmarks.map(
            (item, index) => {
              return <li key={index}>
                <Article item={item} navigate={this.props.navigate} layout={(this.state.cards ? "card" : "list")} />
              </li>
            }
          )
        }
        </ul>
      </section>
    );
  }
}

export default List;
