import React, { Component } from 'react';

import './Header.css';

class Header extends Component {
  render() {
    return (
      <header className="Header">
        <h1><a href="/" title="Home" onClick={ e => this.props.navigate(e, '/') }>Simone Lippolis <span>| <strong>Bookmarks</strong></span></a></h1>
      </header>
    );
  }
}

export default Header;
