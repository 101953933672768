import React, { Component } from 'react';

import Axios from 'axios';

import './index.css';

import Header from './Header.js';
import Search from './Search.js';
import Title from './Title.js';
import List from './List.js';
import Footer from './Footer.js';
import Loading from './Loading.js';

class Bookmarks extends Component {
  constructor(props) {
    super(props);

    this.fetchURL = this.fetchURL.bind( this );
    this.historyChange = this.historyChange.bind( this );
    this.navigate = this.navigate.bind( this );
    this.parseURL = this.parseURL.bind( this );
    this.timeout = this.timeout.bind( this );

    this.endpoint = {
      bookmarks: 'https://api.simonelippolis.com/api/bookmarks/',
      content: 'https://api.simonelippolis.com/api/content/'
    };

    this.timer = 0;

    this.state = {
      bookmarks: [],
      content: {
        quicklinks: '[ "i", "love", "chocolate" ]'
      },
      loading: true,
      started: false,
      term: '',
      timeout: false
    };
  }

  componentDidMount() {
    window.addEventListener( 'popstate', this.historyChange );
    this.timer = setTimeout(
      this.timeout,
      2000
    );
    this.fetchURL( this.endpoint.content + 'bookmarks', {}, (response) => {
      let data = response.data;
      clearTimeout( this.timer );
      if ( data.error ) {
        console.log( data.errormessage );
      } else {
        let content = {};
        data.content.forEach(
          element => {
            content[element.slug] = element.content;
          }
        );
        this.setState(
          { content }
        );
      }
    } );
    this.parseURL()
  }

  componentWillUnmount() {
    window.removeEventListener( 'popstate', this.historyChange );
  }

  fetchURL( url, data, success, error ) {
    Axios.get(
      url,
      { data }
    ).then( (response) => {
      if ( typeof success === 'function' ) {
        success( response );
      }
    }).catch( (err) => {
      console.log( err )
      if ( typeof error === 'function' ) {
        error( err );
      }
    })
  }

  historyChange( e ) {
    this.setState( { loading: true } , this.parseURL );
  }

  navigate( e, url ) {
    e.preventDefault();
    window.history.pushState({ url }, "", url);
    this.setState( { loading: true } , this.parseURL );
  }

  parseURL() {
    let pathname = window.location.pathname;
    let term = null;
    let url = this.endpoint.bookmarks;

    if (pathname.indexOf('/filter/by') === 0) {
      term = pathname.replace(/\/filter\/by\//ig, '');
      if (term) {
        url = url + 'filter/' + (term);
        this.setState( { term: unescape(term) } );
      }
    }
    this.fetchURL( url, {}, (response) => {
      if (response.status === 200) {
        if (response.data.error) {
          console.log( response.data.errormessage );
        } else {
          this.setState(
            { bookmarks: response.data.documents, loading: false, started: true, term: response.data.term || '' },
            () => {
              window.scrollTo(0,0);
            }
          );
        }
      }
    } );
  }

  timeout() {
    clearTimeout( this.timer );
    this.setState( { timeout: true } );
  }

  render() {
    if ( this.state.started ) {
      return (
        <div className="Bookmarks">
          <Header bookmarks={this.state.bookmarks} content={this.state.content} term={this.state.term} fetch={this.fetchURL} navigate={this.navigate} />
          <Search bookmarks={this.state.bookmarks} content={this.state.content} term={this.state.term} fetch={this.fetchURL} navigate={this.navigate} />
          <Title bookmarks={this.state.bookmarks} content={this.state.content} term={this.state.term} fetch={this.fetchURL} navigate={this.navigate} />
          <List bookmarks={this.state.bookmarks} content={this.state.content} term={this.state.term} fetch={this.fetchURL} navigate={this.navigate} />
          <Footer bookmarks={this.state.bookmarks} content={this.state.content} term={this.state.term} fetch={this.fetchURL} navigate={this.navigate} />
          <Loading visible={this.state.loading} />
        </div>
      );
    } else {
      return (
        <div className="Bookmarks">
          <Header navigate={this.navigate} />
          <div className="offline">
            <p>Initializing</p>
            <p><em>{ (this.state.timeout) ? "Maybe you are offline?" : "" }</em></p>
          </div>
          <Footer navigate={this.navigate} />
          <Loading visible={this.state.loading} />
        </div>
      );
    }
  }
}

export default Bookmarks;
